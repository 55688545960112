import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikValues } from 'formik';
import styles from './styles';
import SaveIcon from '@material-ui/icons/Save';
import {
  useGetConfigurationListLazyQuery,
  useUpdateConfigurationMutation,
} from '../../../generated/graphql';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import SettingsCalculatorModal from '../../../components/Modals/SettingsCalculatorModal/SettingsCalculatorModal';
import DualInputField from '../../../components/SettingsCalculatorComponents/DualInputField';
import InputCalculator from '../../../components/SettingsCalculatorComponents/InputCalculator';
import { LOCAL_STORAGE_KEYS, TariffOptions } from '../../../utils/constants';
import { QUERY_CONFIGURATION_LIST } from '../../../GraphQL/queries/getConfigurationList';
import { configurationValidationSchema } from '../../../utils/validationSchemes';
import InputInstalation from '../../../components/SettingsInstallationComponents/InputInstalation';
import UploadProductPhotoComponent from '../../../components/UploadProductPhoto/UploadProductPhotoComponent';

const SettingsInstallation = () => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // const [isOpenModal, setIsOpenModal] = useState(false);
  // const [inputFieldName, setInputFieldName] = useState('');

  // const CURRENT_APP_CURRENCY = localStorage.getItem(
  //   LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  // );

  // const [
  //   queryLazyGetConfigurationList,
  //   {
  //     data: dataQueryLazyGetConfigurationList,
  //     loading: isLoadingQueryLazyGetConfigurationList,
  //   },
  // ] = useGetConfigurationListLazyQuery({
  //   fetchPolicy: 'network-only',
  //   onError: (error) => {
  //     if (error) {
  //       enqueueSnackbar(error?.message, { variant: 'error' });
  //     }
  //   },
  // });

  // const [mutationUpdateConfiguration, { loading: isLoadingUpdate }] =
  //   useUpdateConfigurationMutation({
  //     onError: (error) => {
  //       if (error) {
  //         enqueueSnackbar(error?.message, { variant: 'error' });
  //       }
  //     },
  //   });

  // const configurationListData =
  //   dataQueryLazyGetConfigurationList?.getConfigurationList;

  // useEffect(() => {
  //   queryLazyGetConfigurationList();
  //   // eslint-disable-next-line
  // }, []);

  // if (isLoadingQueryLazyGetConfigurationList)
  //   return <ShowLoadingText name={t('app.previousSettings')} />;
  // if (!dataQueryLazyGetConfigurationList || !configurationListData) {
  //   return <BoxCentered>Нет данных</BoxCentered>;
  // }

  // const initialValues = {
  //   rateUSD: configurationListData?.rateUSD,
  //   wh_order_processing_cost_cases:
  //     configurationListData?.wh_order_processing_cost_cases,
  //   inbound_processing_cost_cases:
  //     configurationListData?.inbound_processing_cost_cases,
  //   undeclared_inbound_processing_cost_expression_pair:
  //     configurationListData?.undeclared_inbound_processing_cost_expression_pair,
  //   return_acceptance_cost: configurationListData?.return_acceptance_cost,
  //   wh_storage_cost_cases: configurationListData?.wh_storage_cost_cases,
  //   wh_order_processing_cost_insurance_pair:
  //     configurationListData?.wh_order_processing_cost_insurance_pair,
  //   wraper_cost_cases: configurationListData?.wraper_cost_cases,
  //   gift_message_cost: configurationListData?.gift_message_cost,
  //   gift_wraper_cost: configurationListData?.gift_wraper_cost,
  //   forwarding_acceptance_cost:
  //     configurationListData?.forwarding_acceptance_cost,
  //   current_app_currency: configurationListData?.current_app_currency,
  // };

  const initialValues = {
    brandName: '',
    logo: '',
    favicon: '',
    password: '',
    bgColor: '',
    userAgreementLink: '',
    personalDataProcessingLink: '',
    footerText: '',
    email: '',
    pushEmail: '',
    userGuideLink: '',
    script: '',
  };

  const handleSubmit = (values: FormikValues) => {
    console.log({ values });
  };

  return (
    <Container>
      <Typography variant='h2' align='center' className={classes.title}>
        {t('Установочные настройки')}
      </Typography>

      <Divider className={classes.titleDivider} />
      <br />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        // validationSchema={configurationValidationSchema}
      >
        {({ values }) => {
          return (
            <Form noValidate autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Название бренда'}
                    name='brandName'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <UploadProductPhotoComponent name='logo' label='Логотип' />
                </Grid>

                <Grid item xs={12}>
                  <UploadProductPhotoComponent name='favicon' label='Фавикон' />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Пароль'}
                    name='password'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Цвет фона'}
                    name='bgColor'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Пользовательское соглашение'}
                    name='userAgreementLink'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Ссылка на страницу обработка перс. данных'}
                    name='personalDataProcessingLink'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Подпись в футере'}
                    name='footerText'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Email контактный'}
                    name='email'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Email для уведомлений'}
                    name='pushEmail'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Ссылка на руководство пользователя'}
                    name='userGuideLink'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={'Скрипты'}
                    name='script'
                    fullWidth
                    multiline
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wh_order_processing_cost_cases')}
                    name='wh_order_processing_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.forwarding_acceptance_cost')}
                    name='forwarding_acceptance_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.inbound_processing_cost_cases')}
                    name='inbound_processing_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DualInputField
                    classes={classes}
                    label={t(
                      'app.undeclared_inbound_processing_cost_expression_pair',
                    )}
                    name='undeclared_inbound_processing_cost_expression_pair'
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.return_acceptance_cost')}
                    name='return_acceptance_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wh_storage_cost_cases')}
                    name='wh_storage_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wh_order_processing_cost_insurance_pair')}
                    name='wh_order_processing_cost_insurance_pair'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wraper_cost_cases')}
                    name='wraper_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.gift_message_cost')}
                    name='gift_message_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.gift_wraper_cost')}
                    name='gift_wraper_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.сurrency')}
                    name='current_app_currency'
                    inputProps={{
                      type: 'text',
                      min: 3,
                    }}
                    setModalName={setInputFieldName}
                    isString
                  />
                </Grid> */}

                {/* TODO  */}
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    startIcon={
                      false ? <CircularProgress size={22} /> : <SaveIcon />
                    }
                  >
                    {t('app.save')}
                  </Button>
                </Grid>
              </Grid>
              {/* <SettingsCalculatorModal
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                inputFieldName={inputFieldName}
              /> */}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SettingsInstallation;

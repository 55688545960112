import { TRoutes } from '../../utils/helpers';
import iconCalculate from '../../assets/img/icon_calculate.png';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
// import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DnsIcon from '@material-ui/icons/Dns';
import StorageIcon from '@material-ui/icons/Storage';
import PlaceIcon from '@material-ui/icons/Place';
import TuneIcon from '@material-ui/icons/Tune';

export const getOptions = (isSuperAdmin: boolean) =>
  isSuperAdmin
    ? [
        // TODO new task new sprint
        // {
        //   link: TRoutes.SETTINGS_INSTALLATION,
        //   Icon: SettingsIcon,
        //   title: 'Установочные настройки', // TODO
        //   tooltipText: 'Перейти к настройкам', // TODO
        // },
        {
          link: TRoutes.SETTINGS_CALCULATOR,
          imgSrc: iconCalculate,
          title: 'app.tariffs',
          tooltipText: 'app.goToTariffs',
        },
        {
          link: TRoutes.WAREHOUSES_LIST,
          Icon: StoreMallDirectoryIcon,
          title: 'app.allWarehouses',
          tooltipText: 'app.goToAllWarehouses',
        },
        {
          link: TRoutes.WAREHOUSES_MANAGERS,
          Icon: SupervisorAccountIcon,
          title: 'app.administrators',
          tooltipText: 'app.goToAdmins',
        },
        {
          link: TRoutes.SETTINGS_SENDER_ACCOUNTS,
          Icon: AccountTreeIcon,
          title: 'app.carriers',
          tooltipText: 'app.goToCarriers',
        },
        {
          link: TRoutes.SETTINGS_B2B_SHIPMENTS_SERVICES,
          Icon: TuneIcon,
          title: 'app.shipmentServices',
          tooltipText: 'app.goB2bShipmentsPoints',
        },
        {
          link: TRoutes.SETTINGS_B2B_SHIPMENTS_POINTS,
          Icon: PlaceIcon,
          title: 'app.b2bShipmentsPoints',
          tooltipText: 'app.goB2bShipmentsPoints',
        },
      ]
    : [
        {
          link: TRoutes.WAREHOUSE_ZONES,
          Icon: DnsIcon,
          title: 'app.warehouseZoning',
          tooltipText: 'app.warehouseZoningGo',
        },
        {
          link: TRoutes.WAREHOUSE_CELLS,
          Icon: StorageIcon,
          title: 'app.cellAddresses',
          tooltipText: 'app.goToCellAddresses',
        },
      ];

import React, { useEffect, useState } from 'react';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import B2bShipmentPackagingStep1 from './ B2bShipmentPackagingStep1';
import B2bShipmentPackagingStep2 from './ B2bShipmentPackagingStep2';
import B2bShipmentPackagingStep3 from './ B2bShipmentPackagingStep3';
import B2bShipmentPackagingStep4 from './ B2bShipmentPackagingStep4';
import clsx from 'clsx';
import SaveIcon from '@material-ui/icons/Save';
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import {
  B2BOrder,
  B2BOrderServiceInput,
  B2BShipmentMethodTypeEnum,
  useGetB2BOrderByIdQuery,
  useSetB2BOrderReadyMutation,
  useSetB2BOrderServicesMutation,
  useSetB2BOrderShipmentInfoMutation,
  // useSetB2BOrderServicesAndShipmentMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import B2bShipmentCreateCargoPlacesModal from '../../../components/Modals/B2bShipmentsModals/B2bShipmentCreateCargoPlacesModal/B2bShipmentCreateCargoPlacesModal';
import { Form, Formik, FormikProps } from 'formik';
import { GET_B2B_ORDER_LIST_QUERY } from '../../../GraphQL/queries/getB2BOrderList';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../utils/helpers';
import { B2bShipmentPackagingFormValidationSchemas } from '../../../utils/validationSchemes';
import { When } from 'react-if';
import { GET_B2B_ORDER_BY_ID_QUERY } from '../../../GraphQL/queries/getB2BOrderById';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';

const B2bShipmentPackaging = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<B2BOrder | null>(null);
  const [isOpenAddCargoPlacesModal, setIsOpenAddCargoPlacesModal] =
    useState(false);

  const itemsLimit = localStorage.getItem(
    LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
  );

  const { data, loading } = useGetB2BOrderByIdQuery({
    variables: {
      getB2BOrderByIdId: +id,
    },
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [
    setB2BOrderServicesMutation,
    { loading: setB2BOrderServicesMutationLoading },
  ] = useSetB2BOrderServicesMutation();

  const [
    setB2BOrderShipmentInfoMutation,
    { loading: setB2BOrderShipmentInfoMutationLoading },
  ] = useSetB2BOrderShipmentInfoMutation();

  const [
    setB2BOrderReadyMutation,
    { loading: setB2BOrderrReadyMutationLoading },
  ] = useSetB2BOrderReadyMutation();

  useEffect(() => {
    if (data) {
      setOrder(data?.getB2BOrderById as B2BOrder);
    }
  }, [data]);

  const handleSetB2BOrderShipmentInfo = (values: B2BOrder) => {
    setB2BOrderShipmentInfoMutation({
      variables: {
        orderId: Number(values.id),
        data: {
          shipmentDate: {
            scheduledDate: values?.shipmentInfo?.scheduledDate,
            scheduledTime: values?.shipmentInfo?.scheduledTime,
          },
          ...(values.shipmentInfo?.typeMethod ===
            B2BShipmentMethodTypeEnum.PicknPack && {
            shipmentDriverInfo: {
              carModel: values.shipmentInfo?.driverInfo?.carModel,
              carNumber: values.shipmentInfo?.driverInfo?.carNumber,
              fullName: values.shipmentInfo?.driverInfo?.fullName,
              mobileNumber: values.shipmentInfo?.driverInfo?.mobileNumber,
            },
          }),
        },
      },
      onCompleted: (data) => {
        if (data) {
          setActiveStep((prevState) => prevState + 1);
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_ORDER_LIST_QUERY],
    });
  };

  const handleSubmit = (values: B2BOrder) => {
    setB2BOrderReadyMutation({
      variables: {
        orderId: Number(id),
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.b2bShipmentSuccessfullyCreated'), {
            variant: 'success',
          });
          history.push(TRoutes.B2B_ORDERS);
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [
        {
          query: GET_B2B_ORDER_LIST_QUERY,
          variables: {
            filter: {
              offset: 0,
              limit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
              search: '',
              dateFilter: null,
              warehouseId: 0,
            },
          },
        },
      ],
    });
  };

  const hadleSaveServices = (values: B2BOrder) => {
    setB2BOrderServicesMutation({
      variables: {
        orderId: Number(values.id),
        services: values?.servicesWithCost?.map((service) => ({
          costInUSD: service?.costInUSD,
          title: service?.title,
          qty: service?.qty,
        })) as B2BOrderServiceInput[],
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.b2bShipmentServicesSuccessfullySaved'), {
            variant: 'success',
          });
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
    });
  };

  const stepCount = 3;

  const orderTitle = order?.barcodeId?.split('.')[0];

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <B2bShipmentPackagingStep1 />;
      case 1:
        return (
          <B2bShipmentPackagingStep2
            setIsOpenModal={setIsOpenAddCargoPlacesModal}
          />
        );
      case 2:
        return <B2bShipmentPackagingStep3 />;
      case 3:
        return (
          <B2bShipmentPackagingStep4
            hadleSaveServices={hadleSaveServices}
            loading={setB2BOrderServicesMutationLoading}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <RequestHandler loading={loading}>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {`${t('app.b2bOrderExecution')} ${orderTitle}`}
      </Typography>
      <Formik
        initialValues={{
          ...order,
          cargoPlaces: order?.cargoPlaces,
          servicesWithCost: !!order?.servicesWithCost?.length
            ? order?.servicesWithCost
            : [
                {
                  id: 0,
                  costInUSD: 0,
                  qty: 1,
                  title: '',
                },
              ],
        }}
        onSubmit={(values: any, helpers) => {
          if (activeStep < stepCount && activeStep !== 2) {
            setActiveStep((prevState) => prevState + 1);
            helpers.setSubmitting(false);
          } else if (activeStep === 2) {
            handleSetB2BOrderShipmentInfo(values);
          } else {
            handleSubmit(values);
          }
        }}
        enableReinitialize
        validationSchema={B2bShipmentPackagingFormValidationSchemas[activeStep]}
      >
        {({ errors }: FormikProps<B2BOrder>) => {
          return (
            <Form noValidate autoComplete='off'>
              <Stepper orientation='horizontal' activeStep={activeStep}>
                <Step>
                  <StepLabel>{t('app.picking')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('app.packaging')}</StepLabel>
                </Step>

                <Step>
                  <StepLabel>{t('app.sending')}</StepLabel>
                </Step>

                <Step>
                  <StepLabel>{t('app.tariffs')}</StepLabel>
                </Step>
              </Stepper>

              <Box>{getStepContent(activeStep)}</Box>

              <div className={classes.actions}>
                <When condition={errors.warehouseStatus}>
                  <p className={classes.errorsMessage}>
                    {t('app.b2bUnpickedError')}
                  </p>
                </When>

                <When
                  condition={
                    Boolean(Object.values(errors).length) &&
                    !errors.warehouseStatus
                  }
                >
                  <p className={classes.errorsMessage}>
                    {t('app.checkAllFieldsAbove')}
                  </p>
                </When>

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  variant='contained'
                  type='button'
                  disabled={activeStep === 0}
                  onClick={() => setActiveStep((prevState) => prevState - 1)}
                  startIcon={<ArrowLeftIcon />}
                >
                  {t('app.back')}
                </Button>

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  type={'submit'}
                  variant='contained'
                  disabled={
                    setB2BOrderrReadyMutationLoading ||
                    setB2BOrderServicesMutationLoading ||
                    setB2BOrderShipmentInfoMutationLoading
                  }
                  startIcon={
                    activeStep < stepCount ? <ArrowRightIcon /> : <SaveIcon />
                  }
                >
                  {activeStep < stepCount ? t('app.next') : 'Создать заявку'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <B2bShipmentCreateCargoPlacesModal
        isOpenModal={isOpenAddCargoPlacesModal}
        setIsOpenModal={setIsOpenAddCargoPlacesModal}
        id={id}
        cargoPlaces={order?.cargoPlaces}
        products={order?.products}
        wrapType={order?.wrapType}
        wrapWay={order?.wrapWay}
      />
    </RequestHandler>
  );
};

export default B2bShipmentPackaging;

import { FormControl, FormHelperText, Grid, Input } from '@material-ui/core';
import { FastField, FieldProps, useFormikContext } from 'formik';
import React from 'react';

import { TProps } from './types';
import { useTranslation } from 'react-i18next';

const InputInstalation = ({
  classes,
  label,
  name,
  inputProps,
  startAdornment,
  fullWidth = false,
  multiline = false,
}: TProps) => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FastField name={name}>
          {({ field: { value, ...field }, meta }: FieldProps) => (
            <FormControl error={meta.touched && !!meta.error}>
              <Input
                disableUnderline
                id={name}
                defaultValue={value}
                className={fullWidth ? '' : classes.inputNumber}
                {...field}
                startAdornment={startAdornment}
                inputProps={inputProps}
                onChange={(event) => {
                  setFieldValue(name, event.target.value);
                }}
                multiline={multiline}
              />
              {meta.touched && !!meta.error && (
                <FormHelperText>{t(meta.error)}</FormHelperText>
              )}
            </FormControl>
          )}
        </FastField>
      </Grid>
    </Grid>
  );
};

export default InputInstalation;

import { Box, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { ChangeEvent, useMemo } from 'react';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import noPhotoIconUrl from '../../assets/img/icon_no_photo.png';
import iconUploadPhotoUrl from '../../assets/img/icon_upload_photo.png';
import iconEditPhotoUrl from '../../assets/img/icon_edit_photo.png';
import iconDeletePhotoUrl from '../../assets/img/icon_delete_photo.png';
import { useFormikContext } from 'formik';

const UploadProductPhotoComponent = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  const { t } = useTranslation();
  const classes = styles();

  const { values, setFieldValue } = useFormikContext<any>();

  const value = values[name];

  const handleUploadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      setFieldValue(name, file);
    }
  };

  const handleDeletePhoto = () => {
    setFieldValue(name, null);
  };

  const photoSrc = useMemo(() => {
    const photo = value;
    if (!photo) return null;
    return typeof photo === 'string' ? photo : URL.createObjectURL(photo);
  }, [value]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box width={'100%'} p={'24px'}>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            marginBottom={'24px'}
          >
            {!!photoSrc ? (
              <img className={classes.image} src={photoSrc} alt={'product'} />
            ) : (
              <img
                className={classes.image}
                src={noPhotoIconUrl}
                alt={'no_photo'}
              />
            )}
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
          >
            <InputLabel htmlFor={name}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {photoSrc ? (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={classes.uploadWrapper}
                  >
                    <img
                      src={iconEditPhotoUrl}
                      alt={'edit_photo'}
                      className={classes.icon}
                    />
                    <Typography>{t('app.changePhoto')}</Typography>
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={classes.uploadWrapper}
                  >
                    <img
                      src={iconUploadPhotoUrl}
                      alt={'upload_photo'}
                      className={classes.icon}
                    />
                    <Typography>{t('app.uploadPhoto')}</Typography>
                  </Box>
                )}
              </Box>
            </InputLabel>
            <input
              id={name}
              className={classes.input}
              type='file'
              accept='image/*'
              onChange={handleUploadPhoto}
            />
            {photoSrc ? (
              <Box
                onClick={handleDeletePhoto}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                mb={'8px'}
                className={classes.uploadWrapper}
              >
                <img
                  src={iconDeletePhotoUrl}
                  alt={'delete_photo'}
                  className={classes.icon}
                />
                <Typography>{t('app.delete')}</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UploadProductPhotoComponent;
